<div class="row">
  <!-- [ sample-page ] start -->
  <div class="d-flex justify-content-end mb-1">
    <div class="col-auto">
      <select class="form-select" [(ngModel)]="refreshInterval" (change)="changeRefreshInterval(refreshInterval)">
        <option value="5000">5 seconds</option>
        <option value="10000">10 seconds</option>
        <option value="15000">15 seconds</option>
        <option value="30000">30 seconds</option>
        <option value="60000">60 seconds</option>
        <!-- Add more options as needed -->
      </select>
    </div>
  </div>
  <div class="col-xl-4 col-md-6">
    <div class="card bg-secondary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar fs-1">
              <i class="ti ti-arrow-up-right-circle opacity-50"></i>
            </div>
          </div>
        </div>
        <span class="text-white d-block fs-1 fw-bold my-2">{{ data.intouchProgressCount }}</span>
        <p class="mb-0 opacity-50">In Progress Orders (InTouch → Avery)</p>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-6">
    <div class="card bg-primary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar fs-1">
              <i class="ti ti-arrow-down-left-circle opacity-50"></i>
            </div>
          </div>
        </div>
        <span class="text-white d-block fs-1 fw-bold my-2">{{ data.averyProgressCount }}</span>
        <p class="mb-0 opacity-50">In Progress Orders (Avery → InTouch)</p>
      </div>
    </div>
  </div>
  <!-- <div class="col-xl-4 col-md-6">
    <div class="card bg-secondary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-group" ngbDropdown>
              <a type="button" class="avtar bg-secondary dropdown-toggle arrow-none" data-bs-toggle="dropdown" ngbDropdownToggle>
                <i class="ti ti-dots"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <li><button class="dropdown-item" type="button">Import Card</button></li>
                <li><button class="dropdown-item" type="button">Export</button></li>
              </ul>
            </div>
          </div>
        </div>
        <span class="text-white d-block fs-1 fw-bold my-2">1350 <i class="ti ti-arrow-up-right-circle opacity-50"></i></span>
        <p class="mb-0 opacity-50">Total Pending Orders</p>
      </div>
    </div>
  </div> -->
  <!-- <div class="col-xl-4 col-md-6">
    <div class="card bg-primary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <ul ngbNav class="nav-tabs nav-pills" #nav="ngbNav" (navChange)="onNavChange($event)">
              <li class="nav-item" [ngbNavItem]="1">
                <a class="nav-link text-white" ngbNavLink>Month</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-6">
                      <span class="text-white d-block fs-1 fw-bold my-2">$108 <i class="ti ti-arrow-up-right-circle opacity-50"></i></span>
                      <p class="mb-0 opacity-50">Total Earning</p>
                    </div>
                    <div class="col-6">
                      <div id="tab-chart-1"></div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li class="nav-item" [ngbNavItem]="2">
                <a class="nav-link text-white" ngbNavLink>Year</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-6">
                      <span class="text-white d-block fs-1 fw-bold my-2">$961<i class="ti ti-arrow-down-right-circle opacity-50"></i></span>
                      <p class="mb-0 opacity-50">C/W Last Year</p>
                    </div>
                    <div class="col-6">
                      <div id="tab-chart-2"></div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
        <div [ngbNavOutlet]="nav"> </div>
      </div>
    </div>
  </div> -->
  <div class="col-xl-4 col-md-12">
    <div class="card bg-secondary-dark dashnum-card dashnum-card-small text-white overflow-hidden mb-1">
      <span class="round bg-primary small"></span>
      <span class="round bg-primary big"></span>
      <div class="card-body p-3">
        <div class="d-flex align-items-center">
          <div class="avtar">
            <i class="text-white ti ti-credit-card"></i>
          </div>
          <div class="ms-2">
            <h4 class="text-white mb-1">{{ data.intouchOrderCount }} <i class="ti ti-arrow-up-right-circle opacity-50"></i></h4>
            <p class="mb-0 opacity-50 text-sm">Total InTouch Completed Orders</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card bg-primary-dark dashnum-card dashnum-card-small text-white overflow-hidden">
      <span class="round bg-primary small"></span>
      <span class="round bg-primary big"></span>
      <div class="card-body p-3">
        <div class="d-flex align-items-center">
          <div class="avtar">
            <i class="text-white ti ti-credit-card"></i>
          </div>
          <div class="ms-2">
            <h4 class="text-white mb-1">{{ data.averyOrderCount }} <i class="ti ti-arrow-down-left-circle opacity-50"></i></h4>
            <p class="mb-0 opacity-50 text-sm">Total Avery Completed Orders</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-12 col-md-12">
    <div class="card dashnum-card dashnum-card-small overflow-hidden">
      <span class="round bg-warning small"></span>
      <span class="round bg-warning big"></span>
      <div class="card-body p-3">
        <div class="d-flex align-items-center">
          <div class="avtar bg-light-warning">
            <i class="text-warning ti ti-credit-card"></i>
          </div>
          <div class="ms-2">
            <h4 class="mb-1">{{ data.orderCount }} <i class="ti ti-arrow-up-right-circle opacity-50"></i></h4>
            <p class="mb-0 opacity-50 text-sm">Total Orders</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-md-6">
    <div class="card">
      <div class="card-body">
        @if(!intouchChartLoading) {
        <div>
          <div class="row mb-3 align-items-center">
            <div class="col">
              <h3>InTouch → Avery</h3>
              <small>In Progress Orders</small>
            </div>
            <!-- <div class="col-auto">
              <select class="form-select">
                <option>Today</option>
                <option selected>This Month</option>
                <option>This Year</option>
              </select>
            </div> -->
          </div>
          <div id="intouchChart">
            <apx-chart
              [series]="intouchChartOptions.series"
              [chart]="intouchChartOptions.chart"
              [dataLabels]="intouchChartOptions.dataLabels"
              [legend]="intouchChartOptions.legend"
              [plotOptions]="intouchChartOptions.plotOptions"
              [responsive]="intouchChartOptions.responsive"
              [xaxis]="intouchChartOptions.xaxis"
              [colors]="intouchChartOptions.colors"
              [grid]="intouchChartOptions.grid"
              [tooltip]="intouchChartOptions.tooltip"
            ></apx-chart>
          </div>
        </div>
      } @else {
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      }
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-md-6">
    <div class="card">
      <div class="card-body">
        @if(!averyChartLoading) {
          <div>
            <div class="row mb-3 align-items-center">
              <div class="col">
                <h3>Avery → InTouch</h3>
                <small>In Progress Orders</small>
              </div>
            </div>
            <div id="averyChart">
              <apx-chart
                [series]="averyChartOptions.series"
                [chart]="averyChartOptions.chart"
                [dataLabels]="averyChartOptions.dataLabels"
                [legend]="averyChartOptions.legend"
                [plotOptions]="averyChartOptions.plotOptions"
                [responsive]="averyChartOptions.responsive"
                [xaxis]="averyChartOptions.xaxis"
                [colors]="averyChartOptions.colors"
                [grid]="averyChartOptions.grid"
                [tooltip]="averyChartOptions.tooltip"
              ></apx-chart>
            </div>
          </div>
        } @else {
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <!-- <div class="col-xl-4 col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <h4>Popular Stocks</h4>
          </div>
          <div class="col-auto"> </div>
        </div>
        <div class="rounded bg-light-secondary overflow-hidden mb-3">
          <div class="px-3 pt-3">
            <div class="row mb-1 align-items-start">
              <div class="col">
                <h5 class="text-secondary mb-0">Bajaj Finery</h5>
                <small class="text-muted">10% Profit</small>
              </div>
              <div class="col-auto">
                <h4 class="mb-0">$1839.00</h4>
              </div>
            </div>
          </div>
          <div id="bajajchart">
            <apx-chart
              [series]="chartOptions1.series"
              [chart]="chartOptions1.chart"
              [dataLabels]="chartOptions1.dataLabels"
              [plotOptions]="chartOptions1.plotOptions"
              [responsive]="chartOptions1.responsive"
              [colors]="chartOptions1.colors"
              [stroke]="chartOptions1.stroke"
            ></apx-chart>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          @for (list of ListGroup; track list) {
            <li class="list-group-item px-0">
              <div class="row align-items-start">
                <div class="col">
                  <h5 class="mb-0">{{ list.name }}</h5>
                  <small class="text-success">{{ list.profit }}</small>
                </div>
                <div class="col-auto">
                  <h4 class="mb-0">
                    {{ list.invest
                    }}<span class="ms-2 align-top avtar avtar-xxs {{ list.bgColor }}"
                      ><i class="{{ list.icon }} {{ list.color }}"></i
                    ></span>
                  </h4>
                </div>
              </div>
            </li>
          }
        </ul>
        <div class="text-center">
          <a href="javascripr:" class="b-b-primary text-primary">View all <i class="ti ti-chevron-right"></i></a>
        </div>
      </div>
    </div>
  </div> -->
  <!-- [ sample-page ] end -->
</div>
  